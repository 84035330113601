import Grid from "@mui/material/Unstable_Grid2";
import { Fragment } from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

const SerieAverageContainer = styled(Box)(({ theme }) => ({
  textAlign: "center",
  fontWeight: "bold",
  display: "flex",
  flexDirection: "column",
  gap: "0.3rem",
}));

const TitleContainer = styled(Box)(({ theme }) => ({
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  gap: "0.1rem",
  margin: "1rem 0rem",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "500",
}));
const SmallText = styled(Typography)(({ theme }) => ({
  fontSize: "0.8rem",
  textAlign: "center",
  fontWeight: "500",
}));

const AverageSerieDescription = styled(Typography)(({ theme }) => ({
  fontSize: "0.71rem",
  textAlign: "center",
  fontWeight: "500",

  color: "rgb(91, 93, 93)",
}));

const AverageSerieValue = styled(Typography)(({ theme }) => ({
  fontSize: "1.3rem",
  textAlign: "center",
  fontWeight: "500",

  color: "rgba(66, 63, 66)",
}));

const ChartAverage = ({ periodselected, averageValues }) => {
  const { start, end } = averageValues;

  return (
    <Fragment>
      <TitleContainer>
        <Title>Promedios</Title>
        <SmallText>{"De " + start + " a " + end}</SmallText>
      </TitleContainer>

      <Grid container spacing={2} alignItems="center" justifyContent="center">
        {averageValues.serieAverage.map((ele, index) =>
          ele.isVisible ? (
            <Grid xs={4} md={3} lg={2} key={index}>
              <SerieAverageContainer variant="outlined">
                <AverageSerieDescription>
                  {ele.description}
                </AverageSerieDescription>
                <AverageSerieValue>{Math.round(ele.average)}</AverageSerieValue>
              </SerieAverageContainer>
            </Grid>
          ) : null
        )}
      </Grid>
    </Fragment>
  );
};

export default ChartAverage;
