import { styled, Grid } from "@mui/material";
export const CardHeader = styled("span")(() => ({
  margin: "0.875rem",
  fontSize: "0.875rem",
  color: "#34314c",
  fontWeight: 500,
  textTransform: "capitalize",
}));

export const ContentBox = styled("div")(({ theme }) => ({
  margin: "1rem 2rem",
  [theme.breakpoints.down("md")]: { margin: "0.5rem 0rem" },
}));

export const ContentChart = styled("div")(({ theme }) => ({
  marginTop: "0.3rem",
  backgroundColor: "#fff",
  padding: "0.5rem",
  [theme.breakpoints.down("md")]: { margin: "0rem", padding: "0.5rem 0.1rem" },
}));

export const Title = styled("div")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize",
  width: "50%",
  padding: "0.6rem",
  display: "inline-block",
}));

export const SubTitle = styled("span")(({ theme }) => ({
  color: "#34314c",
  fontWeight: 500,
  textTransform: "capitalize",
}));

export const RefreshTime = styled("div")(() => ({
  color: "#34314c",
  fontWeight: 420,
  fontSize: "0.71rem",
  position: "absolute",
  top: "2rem",
  right: "3rem",
}));

export const DataTable = styled("div")(() => ({
  whiteSpace: "pre",
  "& small": {
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)",
  },
}));

export const GridData = styled(Grid)(() => ({
  whiteSpace: "pre",
  padding: "10px 5px",
  // ".MuiTableCell-root": {
  //   textAlign: "center",
  // },
  ".titleDensimeter": {
    fontWeight: 600,
    margin: "10px 0px",
  },
}));
