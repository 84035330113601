import { Box, Card, Typography, useMediaQuery } from "@mui/material";
import { CardHeader, DataTable } from "app/components/styledComponents";

const CardWrapper = ({ children, title, queryTime }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Card elevation={2} sx={{ padding: "0rem 0.5rem" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "0rem 0.5rem",
        }}
      >
        {title && <CardHeader>{title}</CardHeader>}

        {isMobile && (
          <Typography
            variant="caption"
            // style={{ padding: "0.5rem", float: "right" }}
          >
            {queryTime ?? ""}
          </Typography>
        )}
      </Box>
      <DataTable>{children}</DataTable>
    </Card>
  );
};

export default CardWrapper;
